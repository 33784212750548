<template>
  <div>
    <a-form
      class="simiot-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="10" :xxl="8">
          <a-form-item>
            <a-input
              v-decorator="['iccid_or_phone_number', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入ICCID或SIMID或手机号"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="10" :xxl="8" v-show="expand">
          <a-form-item label="服务周期结束时间">
            <a-range-picker
              style="width: 100%;"
              :disabled-date="disabledDate"
              :placeholder="['开始日期','结束日期']"
              v-decorator="['service_end_date']"
            />
          </a-form-item>
        </a-col>

        <a-col :span="10" :xxl="8" v-show="expand">
          <a-form-item label="运营商账户">
            <a-select
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              v-decorator="['carrier_account_id']"
              :loading="loadingCarrierAccountOptions"
              allow-clear
              @focus="fetchAllCarrierAccountOptions"
            >
              <a-select-option
                v-for="option in carrierAccountOptions"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="10" :xxl="8" v-show="expand">
          <a-form-item label="用户业务模式">
            <a-select
              v-decorator="['user_business_type']"
              allow-clear
            >
              <a-select-option
                v-for="option in userBusinessTypeOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="10" :xxl="8">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import {
  findUserBusinessTypeOptions,
  findAllCarrierAccountOptions
} from '@/api/common_options'
import { formatRangeBeginDate, formatRangeEndDate } from '@/utils/time'
export default {
  name: 'SearchAgentServiceCycleExpiredSimCard',
  props: {
    agentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      expand: false,
      carrierAccountOptions: [],
      userBusinessTypeOptions: findUserBusinessTypeOptions(),
      loadingCarrierAccountOptions: false,
      form: this.$form.createForm(this, { name: 'service_cycle_unexpired_search' })
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    disabledDate(current) {
      // 只能选择历史时间
      return current && current.endOf('day') >= this.$moment().endOf('day')
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    // 加载全部的运营商账户
    fetchAllCarrierAccountOptions(carrierType) {
      this.loadingCarrierAccountOptions = true
      findAllCarrierAccountOptions(carrierType).then(res => {
        if (res.code === 0) {
          this.loadingCarrierAccountOptions = false
          this.carrierAccountOptions = res.data
        }
      })
    },

    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        service_end_date_begin: formatRangeBeginDate(fieldsValue.service_end_date),
        service_end_date_end: formatRangeEndDate(fieldsValue.service_end_date)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      // 清空选项
      this.carrierAccountOptions = []
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
