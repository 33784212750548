import request from '@/utils/request'

// 获取服务周期即将到期的卡
export function findAgentServiceCycleUnexpiredSimCards(params) {
  return request({
    url: `/sim_cards/service_cycle_manage/unexpired`,
    method: 'get',
    params: params
  })
}

// 导出服务周期即将到期的卡
export function exportAgentServiceCycleUnexpiredSimCards(data) {
  return request({
    url: `/sim_cards/service_cycle_manage/unexpired/export`,
    method: 'post',
    data
  })
}

// 导出服务周期即将到期的卡流量
export function exportAgentServiceCycleUnexpiredSimCardsFlow(data) {
  return request({
    url: `/sim_cards/service_cycle_manage/unexpired/export_flow`,
    method: 'post',
    data
  })
}

// 获取服务周期已到期的卡
export function findAgentServiceCycleExpiredSimCards(params) {
  return request({
    url: `/sim_cards/service_cycle_manage/expired`,
    method: 'get',
    params: params
  })
}

// 导出服务周期已到期的卡
export function exportAgentServiceCycleExpiredSimCards(data) {
  return request({
    url: `/sim_cards/service_cycle_manage/expired/export`,
    method: 'post',
    data
  })
}

// 导出服务周期已到期的卡流量
export function exportAgentServiceCycleExpiredSimCardsFlow(data) {
  return request({
    url: `/sim_cards/service_cycle_manage/expired/export_flow`,
    method: 'post',
    data
  })
}

// 获取本月即将销卡的卡
export function findAgentCurrentMonthExpectCancelSimCards(params) {
  return request({
    url: `/sim_cards/service_cycle_manage/current_month_expect_cancel`,
    method: 'get',
    params: params
  })
}

// 导出本月即将销卡的卡
export function exportAgentCurrentMonthExpectCancelSimCards(data) {
  return request({
    url: `/sim_cards/service_cycle_manage/current_month_expect_cancel/export`,
    method: 'post',
    data
  })
}

// 导出本月即将销卡的卡流量
export function exportAgentCurrentMonthExpectCancelSimCardsFlow(data) {
  return request({
    url: `/sim_cards/service_cycle_manage/current_month_expect_cancel/export_flow`,
    method: 'post',
    data
  })
}

// 获取已销卡的卡
export function findAgentCanceledSimCards(params) {
  return request({
    url: `/sim_cards/service_cycle_manage/canceled`,
    method: 'get',
    params: params
  })
}

// 导出已销卡的卡
export function exportAgentCanceledSimCards(data) {
  return request({
    url: `/sim_cards/service_cycle_manage/canceled/export`,
    method: 'post',
    data
  })
}

// 获取续期记录
export function findAgentRenewedSimCards(params) {
  return request({
    url: `/sim_cards/service_cycle_manage/renewed`,
    method: 'get',
    params: params
  })
}

// 导出续期记录
export function exportAgentRenewedSimCards(data) {
  return request({
    url: `/sim_cards/service_cycle_manage/renewed/export`,
    method: 'post',
    data
  })
}
